<style>
@import url("./css/app.css");

body.dark-mode {
  background-color: #212529;
  color: #f8f9fa;
}

.dark-mode .navbar,
.dark-mode .card,
.dark-mode .bg-white {
  background-color: #343a40;
  color: #f8f9fa;
}
</style>
<script setup>
import { onMounted, onUpdated, provide, ref } from "vue"
import { useRouter } from "vue-router"
import { useQuasar, LocalStorage } from "quasar"
import smartFarm from "./js/App.js"
import { useI18n } from "vue-i18n"

const { t, locale } = useI18n({ useScope: "global" });
smartFarm.router = useRouter()
smartFarm.start()
provide("smartFarm", smartFarm);
const setLanguage = (lang) => {
  locale.value = lang;
  LocalStorage.set("frontEndLocale", lang)
}
function openPrivacyPolicy() {
  window.open('privacypolicy.html', '_blank')
}
onUpdated(() => {
  console.log("App onUpdated")
  smartFarm.onUpdated()
});
onMounted(() => {
  if (LocalStorage.getItem("frontEndLocale") === null) {
    LocalStorage.set("frontEndLocale", "ko-KR")
    locale.value = "ko-KR"
  } else {
    locale.value = LocalStorage.getItem("frontEndLocale")
  }
  console.log("App onMounted")
  smartFarm.onMounted()
})
let UnselectedSideBar = 0;
</script>
<template>
  <q-layout view="lHh Lpr lFf" class="bg-white" v-if="smartFarm.errorPage">
    <div>
      <h1>404</h1>
      <p>{{ t("menu.errorPage") }}</p>
    </div>
  </q-layout>
  <q-layout view="lHh Lpr lFf" class="bg-white" v-else>
    <q-header elevated>
      <q-toolbar>
        <q-btn flat @click="smartFarm.drawer = !smartFarm.drawer" round dense color="black">
          <svg width="24" height="24" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.2778 27.2222V31.1111H3.88889V27.2222H25.2778ZM35 13.6111V17.5H0V13.6111H35ZM31.1111 0V3.88889H9.72222V0H31.1111Z"
              fill="#023055" />
          </svg>
        </q-btn>
        <div v-if="$route.meta.headerContent">
          {{ $route.meta.headerContent }}
        </div>
      </q-toolbar>
    </q-header>
    <q-drawer v-model="smartFarm.drawer" side="left" bordered>
      <div class="side-Navbar" >
        <div class="q-px-xl q-pt-md" style="width: 100%;">
          <q-img class='text-center ' :src="smartFarm.logo" fit="contain" />
        </div>
        <q-list class="main-menu">
          <q-item flat class="menu-list  hover-effect" v-for="(menu, index) in smartFarm.menus" :key="index" :class="{ sidebarbutton: index === smartFarm.selectedIndexOfSideBar }"
            :active="index === smartFarm.selectedIndexOfSideBar" :to="menu.to" @click="() => smartFarm.selectSidebar(index)"  style="transition: background-color 0.3s ease; &:hover { background-color: #eee; }">
            <q-item-section avatar> <q-icon :name="menu.icon" /></q-item-section>
            <q-item-section :class="{ 'selectedSideBar': smartFarm.selectedIndexOfSideBar == index, 'text-danger': UnselectedSideBar != index }">
              {{ smartFarm.serverInfo.controller ? t(menu.title) : menu.title === "extra" ? t("menu." + menu.to.name) : menu.title }}
            </q-item-section>
          </q-item>
          <!-- Language section item -->
          <q-item flat class="menu-list">
            <q-btn-dropdown icon="language" :label="t('menu.language')" no-caps unelevated no-icon-animation>
              <q-list>
                <q-item clickable v-close-popup @click="setLanguage('ko-KR')">
                  <q-item-section>
                    <q-item-label>한국어</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="setLanguage('en-US')">
                  <q-item-section>
                    <q-item-label>English</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="setLanguage('am-ET')">
                  <q-item-section>
                    <q-item-label>አማሪኛ</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup @click="setLanguage('zh-CN')">
                  <q-item-section>
                    <q-item-label>简体中文</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-item>
          <!-- mobileServer -->
          <q-item flat class="menu-list" clickablev-if="smartFarm.screen.local || smartFarm.connectedTo === 'mobileServer'">
            <q-item-section avatar>
              <q-icon name="refresh" v-on:click="smartFarm.router.go()" flat color="green" padding />
            </q-item-section>
            <q-item-section>
              {{ t("menu.reload") }}
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-drawer>
    <q-footer elevated class="bg-grey-2 text-black">
      <q-toolbar>
        <q-toolbar-title>
          <div>&copy; {{ new Date().getFullYear() }} Ty Eng. All rights reserved.</div>
        </q-toolbar-title>
        <q-space />
        <q-btn
          flat
          no-caps
          label="Privacy Policy"
          color="black"
          @click="openPrivacyPolicy"
        />
      </q-toolbar>
    </q-footer>
    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
  <div class="main-loader" v-if="smartFarm.showLoader">
    <q-spinner-dots color="primary" size="4em" />
    <p class="buttons-label">{{ t("menu.loading") }}</p>
  </div>
  <!-- alert message modal -->
  <q-dialog v-model="smartFarm.alert.show" persistent>
    <q-card class="dialog-card">
      <q-card-section class="row items-center">
        <q-avatar :icon="smartFarm.alert.icon" color="primary" text-color="white" />
        <span class="q-ml-sm">{{ smartFarm.alert.labelPrefix }}{{ t(smartFarm.alert.label) }}</span>
      </q-card-section>
      <q-card-section class="q-pt-none">{{ t(smartFarm.alert.text) }}</q-card-section>
      <q-card-actions align="right" v-if="smartFarm.alert.yesNo">
        <q-btn flat class="buttons-colored" color="red" v-close-popup
          v-on:click="smartFarm.alert.onCancel(smartFarm.alert.param)">
          <q-icon size="sm" name="cancel" />
          <q-tooltip>
            {{ t('smartFarm.no') }}
          </q-tooltip>
        </q-btn>
        <q-btn flat class="circle-buttons" color="white" v-close-popup
          v-on:click="smartFarm.alert.onConfirm(smartFarm.alert.param)">
          <q-icon size="sm" name="done" />
          <q-tooltip>{{ t('smartFarm.yes') }}</q-tooltip>
        </q-btn>
      </q-card-actions>
      <q-card-actions align="right" v-else>
        <q-btn flat class="circle-buttons" color="white" label="확인" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <!-- info message modal -->
  <q-dialog v-model="smartFarm.info.show" position="bottom">
    <q-card style="width: 350px">
      <!-- <q-linear-progress :value="0.6" color="pink" /> -->
      <q-card-section class="row items-center no-wrap">
        <div>
          <div class="text-weight-bold">{{ t(smartFarm.info.label) }}</div>
          <div class="text-grey">{{ t(smartFarm.info.text) }}</div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
