import { reactive } from "vue";

const columns = reactive({
    calculateVPDAndMore: (temperatureCelsius, relativeHumidity) => {
        const R = 461.9066667; // Specific gas constant for water vapor, J/kgK
        const s1 = temperatureCelsius >= 0 ? (7.5 * temperatureCelsius) / (237.3 + temperatureCelsius) : (9.5 * temperatureCelsius) / (265.5 + temperatureCelsius)
        const s2 = s1 < 307.4 ? 1.33322 * (Math.pow(10, 0.6609 + s1)) : 1
        const s3 = relativeHumidity * s2
        const RabsoluteHumidity = (s3 / (R * (273.15 + temperatureCelsius))) * 100 // R절대습도
        const RsaturationVaporDensity = RabsoluteHumidity * 100 / relativeHumidity  // R포화수분
        const absoluteHumidity = RabsoluteHumidity * 10 // 절대습도
        const saturationVaporDensity = RsaturationVaporDensity * 10// 포화수분
        const vaporDeficit = saturationVaporDensity - absoluteHumidity // 수분부족
        const dewPoint = 243.04 * (Math.log(relativeHumidity / 100) + (17.625 * temperatureCelsius / (243.04 + temperatureCelsius))) / (17.625 - Math.log(relativeHumidity / 100) - (17.625 * temperatureCelsius / (243.04 + temperatureCelsius)));
        const saturationVaporPressureDensity = (610.78 * Math.pow(Math.E, (temperatureCelsius / (temperatureCelsius + 233.3) * 17.2694))) / 1000;
        const vaporPressureDeficit = saturationVaporPressureDensity * (1 - (relativeHumidity / 100))
        return {
            absoluteHumidity: absoluteHumidity.toFixed(2),
            SVD: saturationVaporDensity.toFixed(2),
            svpDensity: saturationVaporPressureDensity.toFixed(2),
            vpd: vaporPressureDeficit.toFixed(2),
            dewPoint: dewPoint.toFixed(2)
        };
    },
    items: [
        {
            name: 'phoneNumber',
            label: '동글이번호',
            align: 'left',
            field: 'phoneNumber',
            sortable: true,
            // Assuming you don't want this column to be clickable based on your description
        },
        {
            name: 'timestamp',
            label: '시간',
            field: 'timestamp',
            sortable: true,
            // Assuming timestamp column is not intended to be clickable based on common use cases
        },
        {
            name: 'temperature',
            align: 'left',
            label: '온도 (°C)',
            field: 'temperature',
            sortable: true,
            clickable: true,
        },
        {
            name: 'humidity',
            label: '습도 (%)',
            field: 'humidity',
            sortable: true,
            clickable: true,
        },
        {
            name: 'co2',
            label: 'CO2 (ppm)',
            field: 'co2',
            sortable: true,
            clickable: true,
        },
        {
            name: 'absoluteHumidity',
            label: '절대습도 (g/m³)',
            field: row => columns.calculateVPDAndMore(row.temperature, row.humidity).absoluteHumidity,
            sortable: true,
            clickable: true,
        },
        {
            name: 'svd',
            label: '포화수분',
            field: row => columns.calculateVPDAndMore(row.temperature, row.humidity).SVD,
            sortable: true,
            clickable: true,
        },
        {
            name: 'svpDensity',
            label: 'SVP밀도 (g/m³)',
            field: row => columns.calculateVPDAndMore(row.temperature, row.humidity).svpDensity,
            sortable: true,
            clickable: true,
        },
        {
            name: 'vpd',
            label: '습도결핍 (VPD) (g/m³)',
            field: row => columns.calculateVPDAndMore(row.temperature, row.humidity).vpd,
            sortable: true,
            clickable: true,
        },
        {
            name: 'dewPoint',
            label: '이슬점 (°C)',
            field: row => columns.calculateVPDAndMore(row.temperature, row.humidity).dewPoint,
            sortable: true,
            clickable: true,
        },
        {
            name: 'soilTemperature',
            label: '지온 (°C)',
            field: 'soilTemperature',
            sortable: true,
            clickable: true,
        },
        {
            name: 'soilMoisture',
            label: '토양함수율 (%)',
            field: 'soilMoisture',
            sortable: true,
            clickable: true,
        },
        {
            name: 'ec',
            label: 'EC (dS/m)',
            field: 'ec',
            sortable: true,
            clickable: true,
        },
        {
            name: 'ph',
            label: 'PH (ppm)',
            field: 'ph',
            sortable: true,
            clickable: true,
        },
        {
            name: 'radiation',
            label: '일사량 (W/m²)',
            field: 'radiation',
            sortable: true,
            clickable: true,
        },
    ],
})

export default columns
