const routes = [
  {
    path: "/",
    component: { template: '<div></div>' },
  },
  {
    path: "/smartFarm",
    component: { template: '<div></div>' },
  },
  // 개발환경 -- vue router warning이 떠서 넣는다
  {
    path: "/house1",
    component: { template: '<div></div>' },
  },
  {
    path: "/house2",
    component: { template: '<div></div>' },
  },
  {
    path: "/mobileServer",
    component: { template: '<div></div>' },
  },
  {
    path: "/homeServer",
    component: { template: '<div></div>' },
  },
  {
    path: "/cloudServer",
    component: { template: '<div></div>' },
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/'
  }
];

export default routes;
