import { reactive, computed } from "vue";
import { LocalStorage, Platform } from "quasar";
import { uibuilder } from "boot/uibuilder.js";
import columns from './columns.js'

let smartFarm = reactive({
  darkMode: {
    isDarkMode: true,
    toggleDarkMode: () => {
      console.log(isDarkMode.value);
      isDarkMode.value = !isDarkMode.value;
      console.log(isDarkMode.value);
      if (isDarkMode.value) {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    }
  },
  drawer: false,
  cameras: null,
  alert: {
    show: false,
    labelPrefix: "",
  },
  info: {
    show: false,
    label: "",
    text: "",
  },
  loggedIn: false,
  title: {
    header: "",
    footer: "",
  },
  selectedIndexOfSideBar: 0,
  screen: {
    local: false, // 제어기에 부착되는 스크린
    remote: computed(() => {
      // 원격 컴퓨터 스크린
      console.log("smartFarm.serverInfo.controller", smartFarm.serverInfo.controller);
      console.log("smartFarm.screen.local", smartFarm.screen.local);
      return !(smartFarm.screen.local || smartFarm.serverInfo.controller);
    }),
    mobile: computed(() => {
      return Platform.is && Platform.is.mobile ? Platform.is.mobile : false;
    }),
    touch: computed(() => {
      // 모바일 또는 제어기 터치스크린
      return smartFarm.screen.mobile || smartFarm.screen.local;
    }),
    orientation: computed(() => {
      return (
        (screen.orientation || {}).type ||
        screen.mozOrientation ||
        screen.msOrientation
      );
    }),
  },
  logo: computed(() => {
    // console.log("checking LOGO LOGO LOGO LOGO LOGO LOGO")
    if (window.location.href.includes("dk") || window.location.href.includes("192.168.120.1") || window.location.href.includes("10.8.0.7")) {
      return "/icons/dk_logo1.png"
    } else if (window.location.href.includes("matc")) {
      return "/icons/matc.png"
    } else {
      return "/icons/tyeng.png"
    }
  }),
  connectedTo: "",
  serverInfo: {},
  controllerInfo: {},
  deviceServers: {},
  msg: {},
  menuIndex: 0,
  color: [
    {
      text: "green",
      bg: "bg-green-6",
    },
    {
      text: "lime-10",
      bg: "bg-lime-9",
    },
    {
      text: "purple-8",
      bg: "bg-deep-purple-5",
    },
    {
      text: "indigo-6",
      bg: "bg-light-indigo-14",
    },
    {
      text: "red-10",
      bg: "bg-red-6",
    },
    {
      text: "orange-8",
      bg: "bg-amber-7",
    },
    {
      text: "green",
      bg: "bg-green-6",
    },
    {
      text: "lime-10",
      bg: "bg-lime-9",
    },
    {
      text: "purple-8",
      bg: "bg-deep-purple-5",
    },
    {
      text: "indigo-6",
      bg: "bg-light-indigo-14",
    },
    {
      text: "red-10",
      bg: "bg-red-6",
    },
    {
      text: "orange-8",
      bg: "bg-amber-7",
    },
  ],
  menus: {},
  basePath: "/",
  ioPath: "/uibuilder/vendor/socket.io",
  showLoader: true,
  errorPage: false,
  homePrefix: "",
  cloudPrefix: "",
  uuid: {},
  send(data) {
    // 접속종류 : 클라우드 접속, 홈서버접속, 모바일서버접속, 제어기접속
    // 접속 종류에 따라 사용되는 서버프로그램이 달라진다
    // 메세지의 종착점은 제어기 또는 모바일서버이며 클라우드서버와 홈서버는 요청된 메세지를 제어기에 전달한다
    // 제어기에 전달하기 위해 사용자 화면에서 선택한 홈서버명과 제어기명을 클라우드 서버에, 제어기명을 홈서버에 전달해야 한다
    // 전달된 홈서버명과 제어기명은 MQTT에서 topic으로 활용한다.
    // 모바일서버와 제어기에는 전달할 필요가 없다.
    // debugger
    // console.log("data.topic..........................", data.topic);
    // console.log("smartFarm.roomName..........................", smartFarm.roomName);
    if (smartFarm.roomName !== undefined) {
      smartFarm.cloudPrefix = smartFarm.roomName + "/";
      data.roomName = smartFarm.roomName;
    }
    // console.log("smartFarm", { ...data, cloudPrefix: smartFarm.cloudPrefix, homePrefix: smartFarm.homePrefix, });
    uibuilder.send({
      ...data,
      cloudPrefix: smartFarm.cloudPrefix,
      homePrefix: smartFarm.homePrefix,
    });
  },
  start() {
    // router = routerObject;
    // uibuilder.debug(false);
    // smartFarm.ioPath = "/socket/uibuilder/vendor/socket.io";
    // smartFarm.basePath = "/";
    // uibuilder.start({
    //   namespace: "/smartFarm",
    //   ioPath: smartFarm.ioPath,
    // });
    // const connectSocketServer = () => {
    //   let ioConnected;
    //   (ioConnected = (i) => {
    //     // uibuilder소켓이 연결 될때까지 기다린다.
    //     setTimeout(() => {
    //       if (i > 0) {
    //         if (uibuilder.get("ioConnected")) {
    //           smartFarm.socketId = uibuilder.get("socketId");
    //           // console.log("uibuilder socket is connected");
    //           this.send({
    //             topic: "smartFarm/readPage",
    //           });
    //           i = 0;
    //         } else {
    //           console.log("connecting to smartFarm Server", i);
    //         }
    //         ioConnected(--i);
    //       }
    //     }, 500);
    //   })(30);
    // };
    // connectSocketServer();
  },
  title: computed(() => {
    // 가로세로 모드에 따라 제목을 설정한다.
    if (
      smartFarm.screen.orientation === "portrait-primary" ||
      smartFarm.screen.orientation === "portrait-secondary"
    ) {
      console.log("세로");
      return {
        header: "",
        footer: "DKECO",
      };
    } else if (
      smartFarm.screen.orientation === "landscape-primary" ||
      smartFarm.screen.orientation === "landscape-secondary"
    ) {
      console.log("가로");
      const footer = t("title.header");
      if (smartFarm.screen.touch) {
        return {
          header: "",
          footer: footer,
        };
      } else if (smartFarm.serverInfo.controller) {
        if (
          smartFarm.serverInfo.nickName !== undefined &&
          smartFarm.serverInfo.nickName.length > 0
        ) {
          return {
            header:
              smartFarm.serverInfo.name +
              "(" +
              smartFarm.serverInfo.nickName +
              ")",
            footer: footer,
          };
        } else {
          return {
            header: smartFarm.serverInfo.name,
            footer: footer,
          };
        }
      } else {
        if (
          smartFarm.homeServer !== undefined &&
          smartFarm.homeServer.name !== undefined
        ) {
          return {
            header: smartFarm.homeServer.name,
            footer: footer,
          };
        } else {
          return {
            header: t("title.header"),
            footer: footer,
          };
        }
      }
    } else {
      return {
        header: smartFarm.serverInfo.name,
        footer: t("title.footer"),
      };
    }
  }),
  makeMenus: () => {
    smartFarm.router.addRoute({
      // 접속 단말에 따라 접속 경로가 바뀌므로 거기에 맞춰 기본경로를 설정한다.
      name: "Home",
      path: "/",
      component: () => import("../App.vue"),
    });
    smartFarm.router.addRoute({
      name: "smartControlSystem",
      path: "/",
      component: () => import("../pages/smartControlSystem.vue"),
    });
    smartFarm.router.addRoute({
      name: "tryOut",
      path: smartFarm.basePath,
      component: () => import("../pages/nodeStatus.vue"),
    });
    smartFarm.menus.smartControlSystem = {
      icon: "query_stats",
      title: "extra",
      to: {
        name: "smartControlSystem",
      },
    };
    smartFarm.menus.nodeStatus = {
      icon: "query_stats",
      title: "extra",
      to: {
        name: "tryOut",
      },
    };
    smartFarm.router.push({
      name: "smartControlSystem",
    });
  },
  onUpdated: () => {
  },
  drawGraph: (selectedColumnForGraph) => {
    const params = {
      phoneNumber: smartFarm.dongleFilterTerm,
      sensorName: selectedColumnForGraph.value,

    }
    params.startDate = smartFarm.historyParams.startDate
    params.endDate = smartFarm.historyParams.endDate
    smartFarm.send({
      topic: "smartFarm/sensorHistory",
      params: params

    })
  },

  dongleFilter: '',
  dongleFilterTerm: '',
  enableClick: false,
  filteredSensorData: computed(() => {
    smartFarm.enableClick = false;
    if (!smartFarm.dongleFilterTerm) {
      return smartFarm.sensorData;
    }
    const filteredData = smartFarm.sensorData.filter((row) =>
      row.phoneNumber.startsWith(smartFarm.dongleFilterTerm)
    );
    const hasFullMatch = filteredData.some(row => row.phoneNumber === smartFarm.dongleFilterTerm);
    smartFarm.enableClick = hasFullMatch;
    return filteredData;
  }),
  historyParams: {
    term: "day",
    options: [
      {
        label: '일간',
        value: 'day'
      },
      {
        label: '주간',
        value: 'week'
      },
      {
        label: '월간',
        value: 'month'
      }
    ],
    termSelected: () => {
      const endDate = new Date();
      let startDate;
      console.log(smartFarm.historyParams.term)
      switch (smartFarm.historyParams.term) {
        case "day":
          startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24);
          break;
        case "week":
          startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 7);
          break;
        case "month":
          startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 30);
          break;
        default:
          startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24);
      }
      const formatDateForMySQL = (/** @type {Date} */ date) => {
        return date.toISOString().slice(0, 19).replace('T', ' ');
      };
      smartFarm.historyParams.startDate = formatDateForMySQL(startDate)
      smartFarm.historyParams.endDate = formatDateForMySQL(endDate)
    },
    updateStartDate: () => {
      console.log(smartFarm.historyParams.startDate)
    },
    updateEndDate: () => {
      console.log(smartFarm.historyParams.endDate)
    },
  },

  filterRows: () => {
    smartFarm.dongleFilter = smartFarm.dongleFilterTerm
  },
  customFilterMethod: (rows, terms, cols, cellValueFn) => {
    if (terms.column1) {
      return rows.filter(row =>
        String(cellValueFn(row, cols.find(col => col.name === 'column1')))
          .toLowerCase()
          .startsWith(terms.column1.toLowerCase())
      );
    }
    return rows;
  },
  columns: columns,
  selectedColumnForGraph: '',
  columnOptions: columns.items.filter(item => item.clickable == true).map(column => ({
    label: column.label,
    value: column.name,
  })),
  onMounted: () => {
    smartFarm.makeMenus();
    const endDate = new Date();
    let startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24)
    const formatDateForMySQL = (/** @type {Date} */ date) => {
      return date.toISOString().slice(0, 19).replace('T', ' ');
    };
    smartFarm.historyParams.startDate = formatDateForMySQL(startDate)
    smartFarm.historyParams.endDate = formatDateForMySQL(endDate)
    smartFarm.selectedColumnForGraph = smartFarm.columnOptions[0]
    uibuilder.onChange("msg", function (msg) {
      if (typeof msg.topic !== "string") {
        return;
      } else if (msg.topic === "smartFarm/sensorHistory") {
        smartFarm.msg = JSON.parse(JSON.stringify(msg));
        switch (true) {
          case msg.topic.includes("sensorHistory"):
          case msg.topic.includes("readPage"):
            smartFarm.uuid.sensorHistory = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            break;
          case msg.topic.includes("customerInfo"):
            smartFarm.uuid.sensorHistory = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            break;
        }
        console.log("++++++++++++++++++++++", msg.payload)
        smartFarm.historyChart.options.labels = msg.payload.labels;
        smartFarm.historyChart.series = [
          {
            name: smartFarm.name,
            type: "line",
            data: msg.payload.data,
          },
        ]
        // smartFarm.historyChart.options.yaxis[0].title.text = nodeControl.sensorGraphDevice.edit.name
        // smartFarm.historyChart.chart.options.tooltip.y.formatter = (
        //   value,
        //   { seriesIndex, dataPointIndex, w }
        // ) => {
        //   return value.toFixed(2) + nodeControl.sensorGraphDevice.deviceMap.ValueUnit
        // };


        smartFarm.historyChart.show = true;
      } else {
        console.log(msg)
        smartFarm.msg = JSON.parse(JSON.stringify(msg));
        switch (true) {
          case msg.topic.includes("sensorHistory"):
          case msg.topic.includes("readPage"):
            smartFarm.uuid.sensorHistory = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            break;
          case msg.topic.includes("customerInfo"):
            smartFarm.uuid.sensorHistory = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            break;
        }
        // smartFarm.showLoader = false
        // smartFarm.sensorData = msg.payload.map(item => ({
        //   ...item,
        //   timestamp: new Date(item.timestamp).toISOString().replace('T', ' ').slice(0, 19)
        // }));
        // // const convertUTCToKST = (utcDate) => {
        // //   // Create a date object from the UTC date string
        // //   let date = new Date(utcDate);

        // //   // Add 9 hours to convert UTC to KST (Korea Standard Time is UTC+9)
        // //   // date = new Date(date.getTime() + (9 * 60 * 60 * 1000));

        // //   // Format the date as yyyy-mm-dd HH:MM:SS
        // //   const year = date.getFullYear();
        // //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months start from 0 in JavaScript
        // //   const day = String(date.getDate()).padStart(2, '0');
        // //   const hours = String(date.getHours()).padStart(2, '0');
        // //   const minutes = String(date.getMinutes()).padStart(2, '0');
        // //   const seconds = String(date.getSeconds()).padStart(2, '0');

        // //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        // // };

        // // Assuming msg.payload is an array of objects where each object has a timestamp
        // // .map(item => {
        // //   return {
        // //     ...item,
        // //     timestamp: convertUTCToKST(item.timestamp) // Replace the timestamp field with the KST formatted string
        // //   };
        // // });
        // smartFarm.pagination = {
        //   sortBy: 'timestamp',
        //   descending: true,
        //   rowsPerPage: 50
        // };
      }
    });
    document.title = "TYEng LTD";
  },
  get: function (data) {
    uibuilder.get(data);
  }
});

console.log(
  "screen resolution is " +
  window.screen.width * window.devicePixelRatio +
  "x" +
  window.screen.height * window.devicePixelRatio
);
export default smartFarm;
